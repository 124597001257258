<template lang="pug">
    v-container(   class="fill-height tw-h-screen" )
        div(class="tw-flex tw-flex-row tw-h-full tw-w-full")
            div(class="tw-flex tw-flex-col tw-h-full tw-w-full")
                v-row(class=" tw-flex-grow-0 tw-bg-blue-700")
                    v-col
                        span(class=" tw-font-bold tw-text-white") Keywords tool prototype
                    
                v-row(class=" tw-flex-grow tw-bg-gray-200")
                    v-col(cols="8" class="tw-h-full")
                        div(class="tw-flex tw-flex-row tw-h-full tw-w-full")
                            div(class="tw-flex tw-flex-col tw-h-full tw-w-full")
                                v-row(class="tw-my-0 tw-py-0 flex-grow")
                                    v-col(class="tw-py-0 tw-h-full")
                                        helper-highlightable(
                                            @selection="selection"
                                            @tools="tools"
                                            :key='key'
                                        )
                                            highlightable-input(
                                                v-model="text"
                                                highlight-style="background-color:yellow"
                                                :highlight-enabled="true"
                                                :highlight="keywordsFound"  
                                                outlined
                                                height="600"
                                                hide-details
                                                background-color="white"
                                                id="text-input"
                                                class="tw-h-full fill-height tw-border-gray-400 tw-font-medium tw-bg-white tw-rounded tw-p-4 tw-border"
                                                :key='key'
                                            )
                                        div(
                                            v-if="showTools"
                                            style="height:30px; width:130px "
                                            :style="{left: `${toolsX}px`, top: `${toolsY}px`}"
                                            class="tools"
                                        )
                                            div(class=" tw-text-white tw-font-bold tw-cursor-pointer " @click="addKeyword") Add keyword
                                v-row(  justify="start" class="tw-my-0 tw-flex-grow-0 ")
                                    v-col(  cols="auto" class="tw-text-right tw-mr-0 tw-pr-0 tw-pl-5")
                                        v-icon(v-if="keywordsFound.length >= 1" color="yellow darken-2" class="tw-m-0 tw-p-0 tw-self-end") mdi-checkbox-blank-circle
                                    v-col( cols="auto")
                                        span(v-if="keywordsFound.length >= 1" class="tw-font-medium") Highlighted terms already exist in our keyword index
                                        
                                    //- v-col(cols="auto" class="tw-py-0")
                                        //- v-btn( text @click="reset" )
                                            //- span() Reset
                                        //- v-btn( text outlined @click="getKeywords" )
                                            //- span() Check for keywords
                                

                    
                    v-col(cols="4")
                        v-row(class="tw-my-0")
                            v-col(class="tw-py-0 ")
                                span(class="tw-font-bold") Keywords to add
                        
                        v-row(class="tw-my-0")
                            v-col(class="tw-py-0 tw-pt-2")
                                v-chip(
                                    v-for="(keyword, index) in newKeywords" :key="index"
                                    close
                                    @click:close="removeKeyword(keyword)"
                                    class="tw-font-bold tw-mx-1 tw-my-1"
                                ) {{ keyword }}
                                    
                                
                                    
                    
                    
</template>
<script>
export default {
    data() {
        return {
            text: "",
            keywordsFound: [''],
            showTools: false,
            toolsX: '',
            toolsY: '',
            selectedText: '',
            newKeywords: [],
            key: 1
        }
    },
    computed:{
        result() {
            return this.$store.getters.result
        }
    },
    watch:{
        text(oldText, newText){
            //console.log(this.lodash)
            //this.lodash.debounce(this.getKeywords(), 150)
            this.getKeywords()
        }
    },
    methods: {
        getKeywords(){
            let data = {}
            data.text = this.text
            this.$store.dispatch('getKeywords', data)
            .then(response => {
                if(response.hits.hits.length > 0){
                    let keywordsArray = response.hits.hits
                    keywordsArray.forEach(element => {
                        let queries = element._source.query.dis_max.queries
                        queries.forEach(element => {
                            this.keywordsFound.push(element.match_phrase.percolatedkeyword.query.trim());
                        });
                    });
                }
            }).catch(error => {
                console.log("Error")
                console.log(error)
            })
        },
        onShare (text) {
            console.log('share:', text)
        },

        onHighlight (text) {
            console.log('highlight:', text)
        },
        selection(value){
            value.showTools = this.showTools
            this.toolsX = value.x
            this.toolsY = value.y + 68
            this.selectedText = value.selectedText
        },
        tools(value){
            if(!this.selectedText) {
                this.showTools = value
            }else{
                this.showTools = true
            }
            
        },
        removeKeyword(value){
            this.newKeywords = this.newKeywords.filter(item => item !== value)
        },
        addKeyword(){
            if(!this.newKeywords.find(item => item == this.selectedText)){
                this.showTools = false
                this.newKeywords.push(this.selectedText)
                this.selectedText = null
            }else{
                this.showTools = false
                this.selectedText = null
            }
        },
        reset(){
            this.key++
            this.text = ''
            this.showTools = false
            this.keywordsFound = ['']
        }
    },
}
</script>
<style lang="stylus">

.tools
  height: 30px
  width: 100px
  padding: 5px 10px
  background #333
  border-radius 3px
  position: absolute
  top: 0
  left: 0
  transform: translate(-50%, -100%)
  transition: 0.2s all
  display: flex
  justify-content: center
  align-items: center

.tools:after
  content: ''
  position: absolute
  left: 50%
  top: -5px
  transform: translateX(-50%)
  width: 0
  height: 0
  border-left: 6px solid transparent
  border-right: 6px solid transparent
  border-bottom: 6px solid #333

</style>


